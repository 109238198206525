import axios from 'axios';
import Cookies from 'js-cookie';
import qs from 'qs';

const userData = window.user_data;
const permalink = JSON.parse(localStorage.getItem('permalink'));

const eventHandler = item => {
  const count = item ? item.count : 0;

  let element = document.querySelector('.count-favorite-design');
  if (Modernizr.mobile) {
    element = document.querySelector('.count-mobile-favorite-design');
  }
  const pContent = element ? element.querySelector('p') : null;
  if (pContent) {
    pContent.innerHTML = count;

    if (count > 0) {
      if (!Modernizr.mobile) element.classList.remove('d-none');
      pContent.classList.remove('empty');
      const countLength = String(count);
      if (countLength.length >= 3) {
        element.querySelector('.count-bag').classList.add('more-length');
      }
    } else {
      if (!Modernizr.mobile) element.classList.remove('d-none');
      pContent.classList.add('empty');
    }
  }
};

const getFav = async () => {
  const userID = userData ? userData.id : 0;
  let result = {
    count: 0,
    designs: []
  };

  if (userID) {
    const response = await axios.get(`${permalink.api_design}favorites`, {
      params: {
        userID
      }
    });

    result = {
      count: response.data.count,
      designs: response.data.data
    };

    eventHandler(result);
  } else {
    let designs = Cookies.get('idDesign');

    if (designs) {
      designs = designs.split(',').map(item => parseInt(item));

      result = {
        count: designs.length,
        designs: designs
      };

      eventHandler(result);
    }
  }

  return result;
};

const setFav = async designID => {
  console.log('🚀 ~ file: favorite.js ~ line 73 ~ set ~ designID', designID);
  const userID = userData ? userData.id : 0;
  let result = {
    count: 0,
    designs: []
  };

  if (userID) {
    const response = await axios.post(
      `${permalink.api_design}favorites`,
      qs.stringify({
        userID,
        designID
      })
    );

    result = {
      count: response.data.count,
      designs: response.data.data
    };

    eventHandler(result);
  } else {
    let designs = Cookies.get('idDesign');
    if (designs) {
      designs = designs.split(',').map(item => parseInt(item));
      designs = [...designs, designID];
      Cookies.remove('idDesign');
      Cookies.set('idDesign', designs.join(','), { expires: 30 });

      result = {
        count: designs.length,
        designs: designs
      };
    } else {
      Cookies.set('idDesign', designID, { expires: 30 });
      result = {
        count: 1,
        designs: designID
      };
    }

    eventHandler(result);
  }

  return result;
};

const removeFav = async designID => {
  const userID = userData ? userData.id : 0;
  let result = {
    count: 0,
    designs: []
  };

  if (userID) {
    const response = await axios.delete(`${permalink.api_design}favorites`, {
      params: {
        userID,
        designID
      }
    });

    result = {
      message: response.data
    };
  } else {
    let designs = Cookies.get('idDesign');
    if (designs) {
      designs = designs
        .split(',')
        .map(item => parseInt(item))
        .filter(item => item !== designID);
      if (designs.length) {
        Cookies.set('idDesign', designs.join(','), { expires: 30 });
      } else {
        Cookies.remove('idDesign');
      }
      result = {
        count: designs.length,
        designs: designs
      };

      this.eventHandler(result);
    }
  }

  return result;
};

export { getFav, setFav, removeFav };
